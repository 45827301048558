import '/assets/styles/partials/productbox/productbox.scss';
import kebabCase from 'lodash/kebabCase'

import { useMemo } from 'react'
import loadable from '@loadable/component';
import PropTypes from 'prop-types';

// Context
import { useBasketProductControls } from '/controllers/basket';

const types = {
	'regular': loadable(() => import('./types/regular')),
	'basket': loadable(() => import('./types/basket')),
	'list-summary': loadable(() => import('./types/list-summary')),
	'order-summary': loadable(() => import('./types/order-summary')),
	'list': loadable(() => import('./types/list')),
	'modal': loadable(() => import('./types/modal')),
	'simple': loadable(() => import('./types/simple')),
}

const includeSerialTypes = ['basket', 'order_summary', 'modal', 'simple', 'summary'];

const Productbox = ({ type = 'regular', product, includeSerial: rawIncludeSerial, ...props}) => {
	const BoxElem = types[type] ?? types['regular'];
	const linkParams = useMemo(() => {
		const includeSerial = rawIncludeSerial ?? includeSerialTypes.includes(type);
		
		if(product && product.id && product.slug) {
			const serialSlug = product.serial_title ? kebabCase(product.serial_title) : false;
			if(product.serial_id && product.direct_basket) {
				return {
					type: 'no-serial',
					props: {
						href: 'productDetail',
						params: {
							slug: product.slug,
							id: product.id,
						}
					}
				}
			}
			else if(includeSerial && product.serial_id && serialSlug && serialSlug.length > 0) {
				return {
					type: 'with-serial',
					props: {
						href: 'productDetail',
						params: {
							slug: product.slug,
							id: product.id,
							serialSlug: serialSlug,
							serialID: product.serial_id
						}
					}
				}
			}
			else {
				return {
					type: 'no-serial',
					props: {
						href: 'productDetail',
						params: {
							slug: product.slug,
							id: product.id,
						}
					}
				}
			}
		}
		else {
			return {
				type: false,
				props: {}
			}
		}
	}, [product])

	const basketProductControls = useBasketProductControls(product);

	const componentProps = {
		...props,
		product,
		basketProductControls,
		linkParams,
	}

	return <BoxElem {...componentProps} />
}

Productbox.initialProps = {
	preload: false,
}

Productbox.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	preload: PropTypes.bool,
	product: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([false])]),
}

export default Productbox