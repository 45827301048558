import { useState, useEffect, useMemo, useRef, useCallback } from 'react';

// Controllers
import { useBasket, useBasketProduct } from '.';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';

// Functions
import formatNumber from '@wearetla/tla-essentials-tools/functions/format-number';

export const useBasketProductControls = (rawProduct, selectedSerial, showSuccessModal = true) => {
	const { busy, updateProductQuantity: updateBasketQuantity, initialized } = useBasket();
	const { openModal } = useModals();

	const product = useMemo(() => {
		if(!rawProduct) {
			return rawProduct;
		}
		else {
			return {
				...rawProduct,
				serial_id: selectedSerial ? selectedSerial.id : rawProduct.serial_id,
				quantity: selectedSerial ? selectedSerial.stock : rawProduct.quantity,
			}
		}
	}, [rawProduct, selectedSerial])
	
	const basketProduct = useBasketProduct(product?.serial_id);
	const basketQuantityRef = useRef(basketProduct?.basket_quantity);
	const basketQuantitySerialRef = useRef(basketProduct?.serial_id);
	const basketQuantity = useMemo(() => {
		const newQuantity = basketProduct?.basket_quantity;
		basketQuantityRef.current = newQuantity;
		basketQuantitySerialRef.current = basketProduct?.serial_id;
		return newQuantity;
	}, [basketProduct])

	const [[quantity, quantitySerial], setQuantity] = useState([basketQuantity, product?.serial_id]);

	const increaseQuantity = useCallback((e) => {
		e?.preventDefault?.();
		if(product) {
			checkAndSetQuantity((quantity ?? 0) + product.add_basket_size, true, quantity);
		}
	}, [quantity, product, checkAndSetQuantity]);
	
	const decreaseQuantity = useCallback((e) => {
		e?.preventDefault?.();
		if(product) {
			checkAndSetQuantity((quantity ?? 0) - product.add_basket_size, false, quantity);
		}
	}, [quantity, product, checkAndSetQuantity]);

	const checkAndSetQuantity = useCallback((newQuantity, increase, prevQuantity) => {
		if(product) {
			if(increase && prevQuantity === 0) {
				const startAmount = product.min_basket_add_amount;
				newQuantity = (Math.ceil(startAmount / product.add_basket_size) * product.add_basket_size);
			}
			else {
				const maxAddable = Math.min(product.quantity, product.max_basket_add_amount);
				const maxAddableString = parseInt(maxAddable) !== parseFloat(maxAddable) ? formatNumber(maxAddable) : maxAddable;

				if(newQuantity > maxAddable) {
					if(increase) {
						openModal('alert', { message: maxAddable > 0 ? `Bu üründen sepetinize maksimum ${maxAddableString} ${product.unit_type ?? 'Adet'} ekleyebilirsiniz.` : 'Bu ürünün yeterince stoğu olmadığı için sepetinize eklenemiyor.' });
					}
					newQuantity = Math.floor(maxAddable / product.add_basket_size) * product.add_basket_size;
				}
			}
			setQuantity([newQuantity, product.serial_id]);
		}
	}, [product, selectedSerial]);

	const [quantityDisplay, quantityIsMinimum] = useMemo(() => {
		if(product) {
			return [
				formatNumber(quantity, { forceDecimals: false }),
				quantity <= product.min_basket_amount,
			]
		}
		else {
			return ['', false];
		}
	}, [quantity, product])

	useEffect(() => {
		setQuantity([basketProduct?.basket_quantity, basketProduct?.serial_id]);
	}, [basketProduct])

	useEffect(() => {
		if(initialized) {
			if(quantity !== undefined && quantitySerial === product.serial_id) {
				updateBasketQuantity(product, quantity, showSuccessModal).catch(() => {
					setQuantity([basketQuantityRef.current, basketQuantitySerialRef.current]);
				})
			}
		}
	}, [quantity, quantitySerial, product, initialized, showSuccessModal])

	return {
		product,
		quantity,
		quantityDisplay,
		increaseQuantity,
		decreaseQuantity,
		setQuantity: checkAndSetQuantity,
		quantityIsMinimum,
		busy,
	}
}